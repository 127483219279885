import debounce from 'lodash/debounce';

export default {
  data: () => ({ autoPostDimensions: true }),
  methods: {
    postDimensions() {
      if (!this.$el) {
        this.$on('hook:mounted', () => {
          this.postDimensions();
        });
        return;
      }

      window.parent.postMessage({
        name: 'dimensions',
        data: {
          ww: window.innerWidth,
          wh: window.innerHeight,
          w: this.$route.query['style[width]'] === '100%' ? '100%' : this.$el.offsetWidth,
          h: Math.max(this.$el.offsetHeight, document.body.scrollHeight),
        },
      }, '*');
    },
  },
  created() {
    document.documentElement.className = 'overflow-hidden';
    document.documentElement.style = 'background:none;min-width:auto';
    document.body.style = 'background:none;min-width:auto';
  },
  mounted() {
    this.autoPostDimensions && this.postDimensions();

    const onResize = debounce(() => this.postDimensions(), 300);
    window.addEventListener('resize', onResize);
    this.$on('hook:destroyed', () => window.removeEventListener('resize', onResize));
  },
};

export const component = {
  props: {
    params: {
      type: Object,
      require: true,
    },
    buyable: {
      type: Object,
      require: true,
    },
  },
  computed: {
    text() {
      return this.params.t || this.buyable.name;
    },
  },
};
