<script>
import get from 'lodash/get';
import embedsMixin from '@shared/mixins/embeds';
import EmbedButton from '@school/components/EmbedButton.vue';

export default {
  mixins: [embedsMixin],
  components: { EmbedButton },
  data: () => ({
    buyable: get(window, 'initialState.training', null) || get(window, 'initialState.bundle', null),
  }),
};
</script>

<template>
  <EmbedButton :params="$route.query" v-bind="{ buyable }" />
</template>
