<script>
import { component as embedComponentsMixin } from '@shared/mixins/embeds';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [embedComponentsMixin, utilsMixin],
  computed: {
    finalPrice() {
      const discountPrice = this.buyable.discount_price;
      return discountPrice != null ? discountPrice : this.buyable.price;
    },
  },
  methods: {
    buy() {
      window.parent.postMessage({ name: 'checkout' }, '*');
      this.$gtag.addToCart({
        items: [{
          id: this.buyable.uuid,
          name: this.buyable.name,
          quantity: 1,
          price: this.finalPrice,
        }],
      });
      this.$fbq.addToCart({
        content_ids: [this.buyable.uuid],
        content_name: this.buyable.name,
        value: this.finalPrice,
      });
    },
  },
};
</script>

<template>
  <div
    class="mw-full is-inline-block px-1 pb-2 has-text-centered"
    :style="{
      width: params['style[width]'],
      maxWidth: '100%',
    }">
    <b-button
      type="is-primary is-custom"
      :style="{
        height: 'auto',
        minHeight: params['style[height]'] || 'auto',
        whiteSpace: 'initial',
        borderRadius: params['style[borderRadius]']
      }"
      expanded
      @click="buy">
      {{ text }}
      <template v-if="params.showPrice">
        <template v-if="finalPrice > 0">
          ({{ finalPrice | formatPrice }})
        </template>
        <template v-else>
          (Gratuit)
        </template>
      </template>
    </b-button>
    <p
      v-if="params.showPrice && buyable.discount_price != null"
      class="mt-2">
      Au lieu de
      <span class="tdecoration-line-through">
        <template v-if="buyable.price > 0">
          {{ buyable.price | formatPrice }}
        </template>
        <template v-else>
          (Gratuit)
        </template>
      </span>
    </p>
  </div>
</template>
